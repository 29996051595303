import { useEffect, useState } from "react";
import { AppLayout } from "../../../../components/layouts";
import CardType from "./components/CardType";
import CardTransactions from "./components/CardTransactions";
import {useDispatch, useSelector} from "react-redux";
import UserService from "../../../../services/user.service";
import {setTransactions, setUserInfo, setUsers} from "../../../../redux/reducers/authReducer";
import NotificationService from "../../../../services/notification.service";
import {RootState} from "../../../../redux/store";

const users: any = [];


function User() {
  const [tab, setTab] = useState(0);
  const allUsers = useSelector((state: RootState) => state.auth?.users);


  const dispatch = useDispatch();
  const userService = new UserService();

  const getUserInfo = async() => {
    try {
      const response = await userService.getUserInfo();
      if(response.status){
          dispatch(setUserInfo(response.data));
      }
    }catch(err: any){
      NotificationService.error({
          message: err.message
      })
    }
  };

  const _getTransactions = async() => {
    try {
      const response = await userService.getAllUser();
      console.log({response});
      if(response?.status){
        dispatch(setUsers(response.data));
      }
    } catch(err: any) {
      NotificationService.error({
        message: err.message
      })
    }
  }

  useEffect(() => {
      getUserInfo();
      _getTransactions();
  }, [])

  return (
    <AppLayout
      text={
        <h2 className="text-[24px] font-bold leading-[30px] text-[#1A1C1E]">
          Users
        </h2>
      }
    >
      <div className="px-[2rem] py-[4rem] md:w-full w-fit">
      </div>
      <div className="px-[2rem] py-[4rem] w-full -mt-[6rem]">
        <CardTransactions data={allUsers?.data} />
      </div>
    </AppLayout>
  );
}

export default User;
