import { useEffect, useState } from "react";
import { AppLayout } from "../../../../components/layouts";
import TabHeader from "./components/TabHeader";
import BasicInformationTab from "./BasicInformationTab";
import NotificationsTab from "./NotificationsTab";
import SecurityTab from "./SecurityTab";
import LimitsTab from "./LimitsTab";
import { useDispatch } from "react-redux";
import UserService from "../../../../services/user.service";
import { setUserInfo } from "../../../../redux/reducers/authReducer";
import NotificationService from "../../../../services/notification.service";

function Settings() {
    const [activeTab, setActiveTab] = useState(1);

    const dispatch = useDispatch();
    const userService = new UserService();

    const getUserInfo = async() => {
        try {
        const response = await userService.getUserInfo();
        if(response.status){
            dispatch(setUserInfo(response.data));
        }
        }catch(err: any){
        NotificationService.error({
            message: err.message
        })
        }
    }

    useEffect(() => {
        getUserInfo();
    }, [])


    return (
        <AppLayout
            text={<h2 className="text-[24px] font-bold leading-[30px] text-[#1A1C1E]">Settings</h2>}
        >
          <div className=" px-[2.5rem] py-[3rem] md:w-full w-fit">
                <TabHeader
                    activeTab={activeTab}
                    setActiveTab={(tab: number) => setActiveTab(tab)}
                />
                <div className="mt-7 h-[80vh] overflow-y-auto pb-[50px]">
                    {/*{ activeTab === 0 && */}
                    {/*   <BasicInformationTab /> */}
                    {/*}*/}
                    { activeTab === 1 &&
                        <NotificationsTab />
                    }
                    { activeTab === 2 &&
                       <SecurityTab />
                    }
                    { activeTab === 3 &&
                        <LimitsTab />
                    }
                </div>
             </div>
        </AppLayout>
    )
}

export default Settings;
