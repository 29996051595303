import React, { useState } from 'react';
import { getDateString, getTimeFormat } from '../../../../components/custom-hooks';
import { TradeProps } from './_model';
import EmptyState from '../../../../components/ui/EmptyState';
import emptyIcon from '../../../../assets/icons/no-transaction.svg';
import UserService from '../../../../services/user.service';
import NotificationService from '../../../../services/notification.service';


const tableHeaderStyle =
  "text-[#929EAE] text-[14px] leading-[17.5px] font-semibold";
const tableBodyStyle = "text-[14px] leading-[21px]";
const boxStyle = "px-[2rem] md:w-full w-fit";
const btnStyle = "py-1 px-3 rounded text-[13px] text-white";

interface Props {
  data: TradeProps[],
  refetchSwapHistory: () => void,
}


export default function IncomingTrade({ data, refetchSwapHistory }: Props) {
    const userService = new UserService();

    const [acceptLoading, setAcceptLoading] = useState(false);
    const [rejectLoading, setRejectLoading] = useState(false);

    const handleAcceptSwap = async(id: number | string) => {
        setAcceptLoading(true);
        try{
            const response = await userService.acceptP2pSwap(id);

            setAcceptLoading(false);
            if(!response.status){
                NotificationService.error({
                    message: 'Swap Failed!',
                    addedText: response.message
                });
                return;
            }
            // trigger transaction history refetch 
            refetchSwapHistory();
            NotificationService.success({
                message: response.message
            })
        }catch(err: any) {
            setAcceptLoading(false);
            NotificationService.error({
                message: 'Swap Failed!',
                addedText: err.message
            });
            return;
        }
    };

    const handleRejectSwap = async(id: number | string) => {
        setRejectLoading(true);
        try{
            const response = await userService.rejectP2pSwap(id);

            setRejectLoading(false);
            if(!response.status){
                NotificationService.error({
                    message: 'Swap Failed!',
                    addedText: response.message
                });
                return;
            }
            // trigger transaction history refetch 
            refetchSwapHistory();
            NotificationService.success({
                message: response.message
            })
        }catch(err: any) {
            setRejectLoading(false);
            NotificationService.error({
                message: 'Swap Failed!',
                addedText: err.message
            });
            return;
        }
    };

    const Loader = () => {
        return <div className='p-2 border-2 border-white border-l-0 rounded-full animate-spin'></div>
    }

    return (
        <div className="relative">
        <div className={boxStyle}>
            {data?.length > 0 ?
                <ul className="grid lg:w-full md:w-[1024px] overflow-x-auto mt-5 ">
                {/* table header */}
                <li className="flex py-2.5">
                    <div className={`${tableHeaderStyle} w-[15%]`}>INITIATOR ID</div>
                    <div className={`${tableHeaderStyle} w-[20%]`}>AMOUNT</div>
                    <div className={`${tableHeaderStyle} w-[15%]`}>FROM</div>
                    <div className={`${tableHeaderStyle} w-[15%]`}>TO</div>
                    <div className={`${tableHeaderStyle} w-[15%]`}>DATE</div>
                    <div
                    className={`${tableHeaderStyle} w-[20%] flex justify-center`}
                    >
                    Action/Status
                    </div>
                </li>
                {data.map((item: TradeProps, index: number) => {
                    const statusStyle =
                    item.status === "pending"
                        ? "text-gs-amber bg-gs-amberLess1"
                        : item.status === "completed"
                        ? "text-gs-primary bg-gs-primaryLess1"
                        : "text-gs-red bg-gs-redLess1";
                    return (
                    <li
                        key={index}
                        className={`flex items-center py-2.5 border-t border-[#F5F5F5] `}
                        // onClick={() => handleClick(item)}
                    >
                        <div className={`${tableBodyStyle} w-[15%]`}>
                            {item?.initiatorUid}
                        </div>

                        <div
                        className={`${tableBodyStyle} w-[20%] text-gs-text font-semibold`}
                        >
                        {item.amount}
                        </div>

                        <div className={`${tableBodyStyle} w-[15%] capitalize `}>
                            {item.fromCurrency}
                        </div>

                        <div className={`${tableBodyStyle} w-[15%] capitalize `}>
                            {item.toCurrency}
                        </div>

                        <div className={`${tableBodyStyle} w-[15%] flex justify-center capitalize font-semibold `}>
                        <p className="text-gs-text font-semibold">
                            {getDateString(item.updatedAt)}
                        </p>
                        <p className="text-gs-subText font-normal">
                            {getTimeFormat(item.updatedAt)}
                        </p>
                        </div>

                        <div className={`${tableBodyStyle} w-[15%] flex justify-center capitalize font-semibold `}>
                            {item.status.toLowerCase() === 'pending' ?
                            <div className='flex flex-wrap items-center justify-center lg:gap-x-3 gap-y-2'>
                                <button 
                                    className={`${btnStyle} bg-gradient-to-br bg-green-600 `} 
                                    onClick={() => handleAcceptSwap(item.pid)}
                                    >
                                        {acceptLoading ? <Loader /> : "Accept"}
                                </button>
                                <button 
                                    className={`${btnStyle} bg-gradient-to-br bg-red-500`} 
                                    onClick={() => handleRejectSwap(item.pid)}
                                    >
                                        {rejectLoading ? <Loader /> : "Reject"}
                                </button>
                            </div> 
                            :
                            <p className={`${statusStyle} w-fit px-[25px] py-[6px] rounded-[4px]`}>{item.status}</p>
                            }
                        </div>
                    </li>
                    );
                })}
                </ul> : 
                <EmptyState
                    emptyIcon={emptyIcon}
                    emptyText="You have no Pending history"
                    emptyTextMain="No Pending P2P Transaction"
                />
            }
        </div>

        {/* {toggle && (
            <CustomModal
            modalStyle="lg:w-[35%] w-[85%] mx-auto mt-[9%] bg-white rounded-[16px] p-[25px]"
            closeModal={() => setToggle(false)}
            >
            <TransactionPopup
                setToggle={() => setToggle(false)}
                data={selected}
            />
            </CustomModal>
        )} */}
        </div>
    )
}
