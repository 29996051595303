import {useDispatch, useSelector} from "react-redux";
import {AppLayout} from "../../../../components/layouts";
import AccountSetupVerification from "./AccontSetupVerification";
import AnalysisSection from "./AnalysisSection";
import StatisticsSection from "./StatisticsSection";
import TransactionSection from "./TransactionSection";
import {RootState} from "../../../../redux/store";
import UserService from "../../../../services/user.service";
import {useEffect, useState} from "react";
import NotificationService from "../../../../services/notification.service";
import {setUserInfo} from "../../../../redux/reducers/authReducer";
import {Button, CustomModal} from "../../../../components/ui";
import {ScaleLoader} from "react-spinners";


function Home() {
    const userService = new UserService();
    const dispatch = useDispatch();
    const userInfo = useSelector((state: RootState) => state.auth.userInfo);
    const [transactions, setTransactions] = useState(userInfo.Transactions);
    const [loading, setLoading] = useState(false);
    const [bvn, setBvn] = useState("");
    const [bvnError, setBvnError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false); // State for loading popup


    const createAccount = async () => {
        try {
            const response = await userService.createAccount();
            if (!response.status) {
                NotificationService.error({
                    message: "Account creation failed!",
                    addedText: response?.message
                });
                return
            }
            console.log('account created', response?.data)
            dispatch(setUserInfo(response?.data));
            NotificationService.success({
                message: "Account creation successful!",
                addedText: "Enjoy"
            });
            window.location.reload();

        } catch (error) {
            NotificationService.error({
                message: "Account creation failed!",
                addedText: "Something went wrong"
            });
            return
        }

    }


    const verifyBvn = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        try {
            const hashToken = await userService.generateTrxHash();
            console.log('hash token', hashToken)
            if (hashToken.status) {
                const response = await userService.bvnVerification({b_id: bvn}, hashToken.data.trxToken);
                console.log('bvn verify', response)
                if (!response.status) {
                    NotificationService.error({
                        message: "BVN Verification Failed!"
                    });
                    setLoading(false);
                    setBvnError(response?.message);
                    return
                }
            }
            setBvnError(null);
            createAccount();
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    }

    const getUserInfo = async () => {
        setIsLoading(true); // Show loader popup
        try {
            const response = await userService.getUserInfo();
            if (response.status) {
                dispatch(setUserInfo(response.data));
                setTransactions(response?.data?.Transactions);
            }

        } catch (err: any) {
            NotificationService.error({
                message: err.message
            })
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getUserInfo();
    }, [])


    return (
        <AppLayout
            text={
                <p className="lg:text-[22px] text-[16px] font-light text-[#1A1C1E]">
                    Welcome, <span className="font-[500] capitalize">{`${userInfo?.fullName ?? "Admin"}`}</span> 👋🏼
                </p>
            }
        >
            <div className=" px-[2rem] py-[4rem] md:w-full w-fit">
                {/*{!userInfo.accountCreated && userInfo.country != "Canada" && <AccountSetupVerification accountCreated={userInfo.accountCreated}*/}
                {/*                                                       createAccount={createAccount}/>}*/}
                <div className="grid gap-y-[2rem]">
                    <AnalysisSection/>
                    <StatisticsSection/>
                    <TransactionSection data={transactions}/>
                </div>
            </div>
            {}
            {/*{!isLoading && !userInfo.verificationBvn && userInfo.country != "Canada" &&*/}
            {/*    <CustomModal*/}
            {/*        modalStyle="lg:w-[35%] md:w-[65%] sm:w-[75%] w-[90%] mx-auto lg:mt-[15%] mt-[50%] bg-white rounded-[16px] p-[25px]"*/}
            {/*    >*/}
            {/*        <form onSubmit={verifyBvn}>*/}
            {/*            <h1 className="text-center mb-2 text-[21px] text-gs-primary font-[600]">BVN Verification</h1>*/}
            {/*            <p className="text-center mb-2 text-[15px] text-gs-subSubText">Kindly verify your BVN to*/}
            {/*                complete the account creation process</p>*/}
            {/*            {bvnError &&*/}
            {/*                <div*/}
            {/*                    className="w-full mb-4 py-2 px-3 border rounded-[8px] border-red-700 border-dotted bg-red-100 text-[12px] font-[500] text-center">*/}
            {/*                    {bvnError}*/}
            {/*                </div>*/}
            {/*            }*/}

            {/*            <input*/}
            {/*                type="text"*/}
            {/*                className="w-full py-3 px-6 mb-4 border border-gs-borderInput rounded-[8px] outline-gs-primaryLess1"*/}
            {/*                placeholder="Enter BVN"*/}
            {/*                value={bvn}*/}
            {/*                onChange={e => setBvn(e.target.value)}*/}
            {/*                required*/}
            {/*            />*/}
            {/*            <Button*/}
            {/*                category="button"*/}
            {/*                type="submit"*/}
            {/*                isInverted={false}*/}
            {/*                text={loading ? <ScaleLoader color='#fff' height={12} width={4}/> : "Verify"}*/}
            {/*                btnStyle="w-full py-3 px-6"*/}
            {/*            />*/}
            {/*        </form>*/}
            {/*    </CustomModal>*/}
            {/*}*/}

            {isLoading && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-10">
                    <div className="bg-white p-5 rounded-lg shadow-lg flex flex-col items-center">
                        <div className="loader mb-3">
                            {/* You can replace this with an actual spinner or CSS loader */}
                            <svg className="animate-spin h-6 w-6 text-blue-500" xmlns="http://www.w3.org/2000/svg"
                                 fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                            </svg>
                        </div>
                        <p className="text-gray-700">Processing...</p>
                    </div>
                </div>
            )}
        </AppLayout>

    )
}

export default Home;
