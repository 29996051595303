interface TabHeaderProps {
    activeTab: number;
    setActiveTab: (_arg: number) => void;
}

const tabs = [
    // { name: 'Basic information', id: 0 },
    { name: 'Notification', id: 1 },
    { name: 'Security', id: 2 },
    { name: 'Limits', id: 3 },
]

function TabHeader({ activeTab, setActiveTab}: TabHeaderProps) {
    const activeStyle = "text-gs-text font-[600] border-b-[1.5px] border-gs-primaryLess2/[.6] ";
    const normalStyle = "text-gs-subText text-[16px]  font-[400]"
    return (
        <ul className="w-full border-b  border-gs-borderInput flex gap-x-[1.5rem]">
            {tabs.map((tab) => (
                <li
                    onClick={() => setActiveTab(tab.id)}
                    key={tab.id}
                    className={`${activeTab === tab.id ? activeStyle : normalStyle} px-[2rem] leading-[25px] pt-2 pb-5 hover:cursor-pointer`}
                    >
                    {tab.name}
                </li>
            ))}
        </ul>
    )
}


export default TabHeader;
