import { PayloadAction, createSlice } from "@reduxjs/toolkit";
// import { UserInfoModel } from "./models";

interface AuthStateProps {
  userInfo: any;
  isLoggedIn: boolean;
  transactions: any;
  users: any;
}

const initialState: AuthStateProps = {
  userInfo: {},
  transactions: [],
  isLoggedIn: false,
  users: [],
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserInfo: (
      state: any,
      action: PayloadAction<{ userInfo: any, isLoggedIn: boolean }>,
    ) => {
      state.userInfo = action?.payload;
      state.isLoggedIn = true;
    },
    setTransactions: (state: any, action: PayloadAction<{ transactions: any }>) => {
      state.transactions = action.payload;
      state.isLoggedIn = true;
    },
    setUsers: (state: any, action: PayloadAction<{ transactions: any }>) => {
      state.users = action.payload;
      state.isLoggedIn = true;
    },
    logout: (state: any, action: PayloadAction<{ userInfo: any, isLoggedIn: boolean }>) => {
        state.userInfo = {};
        state.isLoggedIn = true;
    }
  },
});

export const { setUserInfo, setTransactions, setUsers, logout } = authSlice.actions;
export default authSlice.reducer;
