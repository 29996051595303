import emptyTransactionIcon from "../../../../../assets/icons/no-transaction.svg";
import { TransactionTable } from "../../../components";


const mockData = [
    {
        Date: new Date(),
        statusType: "domestic",
        serviceType: "bank transfer",
        amount: 150.75,
        currency: "₦",
        reference: "TXN123456",
        status: "pending",
        isForeign: false,
    },
    {
        Date: new Date(),
        statusType: "foreign",
        serviceType: "card payment",
        amount: 500.00,
        currency: "$",
        reference: "TXN654321",
        status: "successful",
        isForeign: true,
    },
    // Add more mock transactions as needed
];


function CardTransactions({ data, onRowClick }: any) {
  return (
    <div className="w-full border border-gs-borderInput rounded-[14.5px] bg-white p-[1.5rem]">
      <div className="mb-[2.5rem]">
        <h2 className="text-[18px] font-semibold text-gs-text leading-[27px]">
          Transactions
        </h2>
      </div>
      <TransactionTable
        data={data}
        onClick={onRowClick}
        emptyIcon={emptyTransactionIcon}
        emptyText="You have not made any transaction yet"
        emptyTextMain="No Transactions"
      />
    </div>
  );
}

export default CardTransactions;
