import { ReactNode } from "react";
import {
  Home,
  Investment,
  P2P,
  Marketplace,
  MyProjects,
  Settings,
  Transfer,
  User,
  Wallet,
  LandingPage,
  Messages,
  SingleInvestment,
  InvestmentPortfolio,
  InvestMoreSingle,
  NewProject,
  SingleMarketInvestment,
} from "../pages";

import {
  ForgotPassword,
  ResetPassword,
  SignUp,
  SignIn,
  OtpVerification,
  CreateNewPassword,
  AccountSetup,
  ResetCode,
} from "../pages/auth";
import TransactionHistory from "../pages/web-app/pages/wallet/TransactionHistory";
import { PrivateRoute } from "../pages/PrivateRoute";

interface RouteProps {
  path: string;
  exact?: boolean;
  element: ReactNode;
}

const routes: RouteProps[] = [
  // landing page
  { path: "/", element:<SignIn />},

  {
    path: "/admin",
    element: <SignIn />,
  },
  // authentication pages
  { path: "/auth/signup", element: <SignUp /> },
  { path: "/auth/signin", element: <SignIn /> },
  { path: "/auth/forgot-password", element: <ForgotPassword /> },
  { path: "/auth/reset-password", element: <ResetPassword /> },
  { path: "/auth/reset-code", element: <ResetCode /> },
  { path: "/auth/create-new-password", element: <CreateNewPassword /> },
  { path: "/auth/verification", element: <OtpVerification /> },
  { path: "/auth/account-setup", element: <AccountSetup /> },

  // web app
  { path: "/app/home", element: <Home />},
  { path: "/app/investment", element: <Investment />},
  { path: "/app/investment/:investmentId", element: <SingleInvestment /> },
  { path: "/app/marketplace/:investmentId", element: <SingleMarketInvestment /> },
  { path: "/app/investment/invest-more/:investmentId",  element: <InvestMoreSingle /> },
  { path: "/app/investment/view-portfolio", element: <InvestmentPortfolio /> },
  { path: "/app/p2p", element: <P2P /> },
  { path: "/app/marketplace", element: <Marketplace /> },
  { path: "/app/projects", element: <MyProjects /> },
  { path: "/app/projects/new-project",   element: <NewProject /> },
  { path: "/app/settings", element: <Settings /> },
  { path: "/app/transfer", element: <Transfer /> },
  { path: "/app/user", element: <User /> },
  { path: "/app/wallet", element: <Wallet /> },
  { path: "/app/wallet/transaction-history", element: <TransactionHistory /> },
  { path: "/app/messages", element: <Messages /> },
];

export { routes };
