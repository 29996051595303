import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppLayout } from "../../../../components/layouts";
import UserService from "../../../../services/user.service";
import { setUserInfo } from "../../../../redux/reducers/authReducer";
import NotificationService from "../../../../services/notification.service";
import Tabs from "./Tabs";
import { Button, CustomModal } from "../../../../components/ui";
import IncomingTrade from "./IncomingTrade";
import OutgoingTrade from "./OutgoingTrade";
import { RootState } from "../../../../redux/store";

const data = []

function P2P() {
    const dispatch = useDispatch();
    const userService = new UserService();
    const userInfo: any = useSelector((state: RootState) => state.auth.userInfo);

    const tabContainerStyle = "w-full border border-gs-borderInput rounded-[14.5px] bg-white p-[1.5rem] flex justify-between items-center";
    const boxStyle = "px-[2rem] py-[4rem] md:w-full w-fit";

    const [tab, setTab] = useState(0);
    const [tradeModal, setTradeModal] = useState(false);
    const [incomingData, setIncomingData] = useState([]);
    const [outgoingData, setOutgoingData] = useState([])

    const getUserInfo = async() => {
        try {
            const response = await userService.getUserInfo();
            if(response.status){
                dispatch(setUserInfo(response.data));
            }
        }catch(err: any){
            NotificationService.error({
                message: err.message
            })
        }
    }

    const getTradeHistory = async() => {
        try{
            const response = await userService.getTradeHistory();
            if(!response.status){
                NotificationService.error({
                    message: 'Failed!',
                    addedText: response?.message
                });
                return;
            }
            const incomingArr = response.data.data.filter((item: any) => item.recipientUid === userInfo.uid);
            const outgoingArr = response.data.data.filter((item: any) => item.initiatorUid === userInfo.uid);
            setIncomingData(incomingArr);
            setOutgoingData(outgoingArr);
        }catch(err: any){
            NotificationService.error({
                message: 'Failed!',
                addedText: err?.message
            });
            return;
        }
    }

    useEffect(() => {
        // getUserInfo();
        // getTradeHistory();
    }, []);

    const refetchSwapHistory = () => {
        // getTradeHistory();
    }


    return (
        <AppLayout
            text={<h2 className="text-[24px] font-bold leading-[30px] text-[#1A1C1E]">P2P Trading</h2>}
        >
            <div className="relative">
                <div className={boxStyle}>
                    {/* tabs  */}
                    <div className={tabContainerStyle}>
                        <Tabs tab={tab} setTab={(data) => setTab(data)} />
                        {/*<Button*/}
                        {/*    btnStyle="px-[30px] py-[10px] md:w-fit w-full border border-gs-primary"*/}
                        {/*    category="button"*/}
                        {/*    isInverted={false}  */}
                        {/*    handleClick={() => setTradeModal(true)}   */}
                        {/*    text="New Trade" */}
                        {/*    />*/}
                    </div>
                </div>

                <div className={boxStyle}>
                    {tab === 0 && <IncomingTrade refetchSwapHistory={refetchSwapHistory} data={incomingData} /> }
                    {tab === 1 && <OutgoingTrade data={outgoingData} /> }
                </div>

                { tradeModal &&
                    <CustomModal
                        modalStyle="lg:w-[35%] w-[80%] mx-auto mt-[13%] bg-white rounded-[16px] p-[25px]"
                        closeModal={() => setTradeModal(false)}
                    >
                        <div></div>
                    </CustomModal>
                }
            </div>
        </AppLayout>
    )
}

export default P2P;
