import emptyTransactionIcon from "../../../../../assets/icons/no-transaction.svg";
import { UserTable } from "../../../components";


const mockData = [
    {
        Date: "2024-04-06 10:47:19",
        fullName: "Ramon Bello",
        walletTag: "VIP",
        statusType: "Debit",
        serviceType: "Transfer Fee",
        amount: "3.00",
        balance: "4997",
        accountNumber: "1234567890",
        debitAmount: "3.00",
        creditAmount: null,
        reference: "MGR_NGN_5515063745814",
        status: "successful",
        isForeign: false
    },
    {
        Date: "2024-04-07 11:22:33",
        fullName: "Angela Doe",
        walletTag: "Standard",
        statusType: "Credit",
        serviceType: "Fund Transfer",
        amount: "100.00",
        balance: "5097",
        accountNumber: "0987654321",
        debitAmount: null,
        creditAmount: "100.00",
        reference: "MGR_NGN_6574839201113",
        status: "pending",
        isForeign: false
    }
];



function CardTransactions({ data }: any) {
  return (
    <div className="w-full border border-gs-borderInput rounded-[14.5px] bg-white p-[1.5rem]">
      <div className="mb-[2.5rem]">
        <h2 className="text-[18px] font-semibold text-gs-text leading-[27px]">
          All users
        </h2>
      </div>
      <UserTable
        data={data}
        emptyIcon={emptyTransactionIcon}
        emptyText="You have not made any user yet"
        emptyTextMain="No Users"
      />
    </div>
  );
}

export default CardTransactions;
