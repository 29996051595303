import closeIcon from "../../../assets/icons/closeIcon.svg";
import {getDateTimeExtended, handleCopy, truncateText} from "../../../components/custom-hooks";
import copyGreenIcon from "../../../assets/icons/copyGreen.svg";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {useRef} from "react";
import NotificationService from "../../../services/notification.service";
import UserService from "../../../services/user.service"; // Assuming NotificationService is used for alerts

const labelStyle = "text-[14px] tracking-wide leading-[16px] font-[500] text-gs-subText";
const pStyle = "text-[16px] leading-[22px] text-black font-[500] flex flex-wrap items-center gap-x-1";

interface PopupProps {
    setToggle: () => void;
    data: any;
}

function TransactionPopup({setToggle, data}: PopupProps) {
    const userInfo = useSelector((state: RootState) => state.auth.userInfo);
    const ref: any = useRef(null);
    const userService = new UserService();

    const selectedStatusStyle = (status: string) => {
        if (!data) return;

        if (status.toLowerCase() === "pending") {
            return "text-gs-amber bg-gs-amberLess1";
        } else if (status.toLowerCase() === "successful") {
            return "text-gs-primary bg-gs-primaryLess1";
        } else {
            return "text-gs-red bg-gs-redLess1";
        }
    };

    const handleAccept = async () => {
        try {
            const response = await userService.acceptTransaction(data.tid);
            if (response?.status) {
                NotificationService.success({ message: "Transaction accepted successfully!" });
                setToggle();
            } else {
                throw new Error(response?.message || "Failed to accept the transaction.");
            }
        } catch (err: any) {
            NotificationService.error({ message: err.message });
        }
    };

    const handleReject = async () => {
        try {
            const response = await userService.rejectTransaction(data.tid);
            if (response?.status) {
                NotificationService.success({ message: "Transaction rejected successfully!" });
                setToggle();
            } else {
                throw new Error(response?.message || "Failed to reject the transaction.");
            }
        } catch (err: any) {
            NotificationService.error({ message: err.message });
        }
    };


    return (
        <div>
            <div className="flex justify-end">
                <img
                    src={closeIcon}
                    alt="close"
                    onClick={() => setToggle()}
                    className="hover:cursor-pointer flex justify-end"
                />
            </div>

            <div className="flex justify-between items-center py-5 border-b border-gs-borderInput">
                <div>
                    <h2 className="text-[24px] font-[500] leading-[27px] tracking-wide">
                        {userInfo.localCurrencySymbol}
                        {data.statusType.toLowerCase() === "credit" ? data.creditAmount : data.debitAmount}
                    </h2>
                    <p className="text-[14px] leading-[21px] mt-2 text-gs-subText">{getDateTimeExtended(data.Date)}</p>
                </div>
                <p
                    className={`${selectedStatusStyle(data.status)} w-fit px-[30px] py-[6px] rounded-[4px]`}
                >
                    {data?.status}
                </p>
            </div>

            <div className="py-5 grid gap-y-4">
                {/* Existing transaction details */}

                <div className="grid  grid-cols-2">
                    <div className="grid gap-y-1.5  col-span-1">
                        <label className={`${labelStyle}`}>Payment method</label>
                        <p className={`${pStyle} capitalize`}>{data.serviceType}</p>
                    </div>
                    <div className="grid gap-y-1.5 col-span-1">
                        <label className={`${labelStyle}`}>Reference</label>
                        <p className={`${pStyle}`}>
                            {truncateText(data.reference, 20)}
                            <p ref={ref} className="hidden">{data.reference}</p>
                            <img
                                src={copyGreenIcon}
                                alt="copy"
                                className="hover:cursor-pointer"
                                onClick={() => handleCopy(ref)}
                            />
                        </p>
                    </div>
                </div>

                <div className="grid grid-cols-2">
                    <div className="grid col-span-1">
                        <label className={`${labelStyle}`}>{data.type === 'deposit' ? 'Wallet Balance' : 'Fee'}</label>
                        <p className={`${pStyle}`}>{'0.00' || data?.fee}</p>   {/* wallet balnce from state */}
                    </div>
                    <div className="grid  col-span-1">
                        <label className={`${labelStyle}`}>Service Type</label>
                        <p className="w-fit px-[30px] text-[14px] py-[6px] capitalize text-gs-subSubText bg-[#EDF1F3] rounded-[4px] relative top-3">
                            {data.statusType}
                        </p>
                    </div>
                </div>

                {data.statusType.toLowerCase() === "credit" &&
                    <div className="grid grid-cols-2 mt-3">
                        <div className="grid gap-y-1.5 col-span-1">
                            <label className={`${labelStyle}`}>Sender Name</label>
                            <p className={`${pStyle} capitalize`}>{data.fullName}</p>   {/* account name from state */}
                        </div>

                        <div className="grid gap-y-1.5 col-span-1">
                            <label className={`${labelStyle}`}>Sender Tag</label>
                            <p className={`${pStyle}`}>{data.walletTag}</p>   {/* account name from state */}
                        </div>
                    </div>
                }

                {data.statusType.toLowerCase() === "debit" ?
                    <>
                        <div className="grid grid-cols-2 mt-3">
                            <div className="grid gap-y-1.5 col-span-1">
                                <label className={`${labelStyle}`}>To</label>
                                <p className={`${pStyle} capitalize`}>{truncateText(data.fullName || data.email, 25)}</p>   {/* session id from data object */}
                            </div>
                            <div className="grid gap-y-1.5 col-span-1">
                                <label className={`${labelStyle}`}>Bank Name</label>
                                <p className={`${pStyle}`}>{data.bankName || "Greenswift"}</p>   {/* session id from data object */}
                            </div>
                        </div>
                        <div className="grid gap-y-1.5 mt-2">
                            <label className={`${labelStyle}`}>Transaction ID</label>
                            <p className={`${pStyle}`}>{data.transactionID || "***********"}</p>   {/* account name from state */}
                        </div>
                    </>
                    :
                    <div className="grid gap-y-1.5 mt-3">
                        <label className={`${labelStyle}`}>Narration</label>
                        <p className={`${pStyle}`}>{data.narration}</p>   {/* account name from state */}
                    </div>
                }
                {/* Accept/Reject Buttons (if isForeign is true) */}
                {(data.isForeign && data.status == "pending") && (
                    <div className="flex justify-end mt-4">
                        <button
                            onClick={handleAccept}
                            className="px-4 py-2 bg-green-500 text-white rounded-md mr-2"
                        >
                            Accept
                        </button>
                        <button
                            onClick={handleReject}
                            className="px-4 py-2 bg-red-500 text-white rounded-md"
                        >
                            Reject
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default TransactionPopup;
