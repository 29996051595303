import { useState } from "react";
import { getDateString, getTimeFormat } from "../../../components/custom-hooks";
import { CustomModal } from "../../../components/ui";
import TransactionPopup from "./TransactionPopup";
import EmptyState from "../../../components/ui/EmptyState";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {FaCheck, FaLock, FaTimes} from "react-icons/fa";

interface UserProps {
  createdAt: string | Date;
  fullName: string;
  walletTag: string | null;
  canLoan: any;
  serviceType: string;
  amount: number | string;
  localBalance: number | string;
  accountNumber: string | null;
  country: number | string | null;
  state: number | string | null;
  walletBalance: string;
  active: string | any;
  isForeign: boolean;
}

interface DataProps {
  data: UserProps[];
  emptyIcon: any;
  emptyText: string;
  emptyTextMain?: string;
  onClick?: boolean;
}

const tableHeaderStyle = "text-[#929EAE] text-[14px] leading-[17.5px] font-semibold";
const tableBodyStyle = "text-[14px] leading-[21px]";

function UserTable({ data, emptyIcon, emptyText, emptyTextMain, onClick }: DataProps) {
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  const [toggle, setToggle] = useState(false);
  const [selected, setSelected] = useState<any>(null);

  const handleClick = (_arg: any) => {
    if (!onClick) return;
    setToggle(true);
    setSelected(_arg);
  };

  return (
      <div className="relative">
        {data?.length > 0 ? (
            <ul className="grid lg:w-full md:w-[1024px] overflow-x-auto mt-5">
              {/* Table Header */}
              <li className="flex py-2.5">
                <div className={`${tableHeaderStyle} w-[10%]`}>DATE</div>
                <div className={`${tableHeaderStyle} w-[10%]`}>FULL NAME</div>
                <div className={`${tableHeaderStyle} w-[10%]`}>TAG</div>
                <div className={`${tableHeaderStyle} w-[10%]`}>ACCOUNT</div>
                <div className={`${tableHeaderStyle} w-[10%]`}>COUNTRY</div>
                <div className={`${tableHeaderStyle} w-[10%]`}>STATE</div>
                <div className={`${tableHeaderStyle} w-[10%]`}>BALANCE</div>
                <div className={`${tableHeaderStyle} w-[10%]`}>WALLET BALANCE</div>
                <div className={`${tableHeaderStyle} w-[10%]`}>LOAN STATUS</div>
                <div className={`${tableHeaderStyle} w-[10%] flex justify-center`}>STATUS</div>
                {/*<div className={`${tableHeaderStyle} w-[10%] flex justify-center`}>ACTIONS</div>*/}
              </li>

              {data.map((item: UserProps, index: number) => {
                const statusStyle = "text-gs-red bg-gs-redLess1";
                    // item?.status?.toLowerCase() === "pending"
                    //     ? "text-gs-amber bg-gs-amberLess1"
                    //     : item?.status?.toLowerCase() === "successful"
                    //         ? "text-gs-primary bg-gs-primaryLess1": "text-gs-red bg-gs-redLess1";

                return (
                    <li
                        key={index}
                        className={`flex items-center py-2.5 border-t border-[#F5F5F5] ${
                            onClick && "hover:cursor-pointer"
                        }`}
                        onClick={() => handleClick(item)}
                    >
                      <div className={`${tableBodyStyle} w-[10%]`}>
                        <p className="text-gs-text font-semibold">{getDateString(item?.createdAt)}</p>
                        <p className="text-gs-subText font-normal">{getTimeFormat(item?.createdAt)}</p>
                      </div>
                      <div className={`${tableBodyStyle} w-[10%] capitalize`}>{item.fullName}</div>
                      <div className={`${tableBodyStyle} w-[10%] capitalize`}>{item.walletTag || "N/A"}</div>
                      <div className={`${tableBodyStyle} w-[10%]`}>{item.accountNumber || "N/A"}</div>
                      <div className={`${tableBodyStyle} w-[10%]`}>
                        {item.country ? `${item.country}` : "-"}
                      </div>
                      <div className={`${tableBodyStyle} w-[10%]`}>
                        {item.state ? `${item.state}` : "-"}
                      </div>
                      <div className={`${tableBodyStyle} w-[10%] text-gs-text font-semibold`}>
                        {userInfo.localCurrencySymbol} {item?.localBalance ?? "0.00"}
                      </div>
                      <div className={`${tableBodyStyle} w-[10%]`}>{item?.walletBalance}</div>
                      <div className={`${tableBodyStyle} w-[10%] capitalize`}>
                        {/*<p className="w-fit px-[30px] py-[6px] text-gs-subSubText bg-[#EDF1F3] rounded-[4px]">*/}
                        {/*  {item?.statusType}*/}
                        {/*</p>*/}
                        {
                          item?.canLoan ?
                              <button className="text-white bg-green-500 p-2 rounded-full" title="Approve">
                                <FaCheck/>
                              </button>
                              :
                              <button className="text-white bg-red-500 p-2 rounded-full" title="Remove">
                                <FaTimes/>
                              </button>
                        }
                      </div>
                      <div className={`${tableBodyStyle} w-[10%] flex justify-center capitalize font-semibold`}>
                        <p className={`${statusStyle} w-fit px-[30px] py-[6px] rounded-[4px] ${item?.active ? "text-green-500" : "text-yellow-500"}`}>{item?.active ? "Active" : "Pending"}</p>
                      </div>
                      {/*<div className={`${tableBodyStyle} w-[10%] flex justify-center space-x-2`}>*/}
                      {/*  {*/}
                      {/*    !item?.active && item.isForeign?*/}
                      {/*      <p className={`${statusStyle} w-fit px-[30px] py-[6px] rounded-[4px]`}>{"Waiting"}</p>*/}
                      {/*      :*/}
                      {/*      <>*/}
                      {/*        <button className="text-white bg-green-500 p-2 rounded-full" title="Approve">*/}
                      {/*          <FaCheck />*/}
                      {/*        </button>*/}
                      {/*        <button className="text-white bg-red-500 p-2 rounded-full" title="Remove">*/}
                      {/*          <FaTimes />*/}
                      {/*        </button>*/}
                      {/*        <button className="text-white bg-yellow-500 p-2 rounded-full" title="Lock">*/}
                      {/*          <FaLock />*/}
                      {/*        </button>*/}
                      {/*      </>*/}
                      {/*  }*/}
                      {/*</div>*/}
                    </li>
                );
              })}
            </ul>
        ) : (
            <EmptyState emptyIcon={emptyIcon} emptyText={emptyText} emptyTextMain={emptyTextMain}/>
        )}

        {toggle && (
            <CustomModal modalStyle="lg:w-[35%] w-[85%] mx-auto mt-[9%] bg-white rounded-[16px] p-[25px]"
                         closeModal={() => setToggle(false)}>
              <TransactionPopup setToggle={() => setToggle(false)} data={selected}/>
            </CustomModal>
        )}
      </div>
  );
}

export default UserTable;
