import { useEffect, useState } from "react";
import { AppLayout } from "../../../../components/layouts";
import CardTransactions from "./components/CardTransactions";
import { useDispatch, useSelector } from "react-redux";
import UserService from "../../../../services/user.service";
import { setTransactions, setUserInfo } from "../../../../redux/reducers/authReducer";
import NotificationService from "../../../../services/notification.service";
import { RootState } from "../../../../redux/store";

const filterOptions = [
    { value: "show-all", label: "Show All" },
    { value: "pending", label: "Pending" },
    { value: "success", label: "Success" },
    { value: "deposit", label: "Deposit" },
    { value: "withdrawal", label: "Withdrawal" },
];

function Transfer() {
    const transactions = useSelector((state: RootState) => state.auth?.transactions);
    const [filteredTransactions, setFilteredTransactions] = useState([]);
    const [filterKey, setFilterKey] = useState("show-all");
    const [activeTab, setActiveTab] = useState("local");
    const [showTransactionModal, setShowTransactionModal] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState<any>(null);

    const dispatch = useDispatch();
    const userService = new UserService();

    const getUserInfo = async () => {
        try {
            const response = await userService.getUserInfo();
            if (response?.status) {
                dispatch(setUserInfo(response.data));
            }
        } catch (err: any) {
            NotificationService.error({ message: err.message });
        }
    };

    const _getTransactions = async () => {
        try {
            const response = await userService.getAllTransaction();
            if (response?.status) {
                dispatch(setTransactions(response.data));
            }
        } catch (err: any) {
            NotificationService.error({ message: err.message });
        }
    };

    const applyFilter = () => {
        const filtered = transactions?.filter((transaction: any) => {
            const isLocal = !transaction.isForeign;
            const matchesTab = activeTab === "local" ? isLocal : !isLocal;
            const matchesFilter =
                filterKey === "show-all" ||
                transaction.status === filterKey ||
                transaction.type === filterKey;

            return matchesTab && matchesFilter;
        });

        setFilteredTransactions(filtered || []);
    };

    const handleRowClick = (transaction: any) => {
        if (activeTab === "foreign") {
            setSelectedTransaction(transaction);
            setShowTransactionModal(true);
        }
    };

    const handleAccept = () => {
        NotificationService.success({ message: "Transaction accepted successfully!" });
        setShowTransactionModal(false);
    };

    const handleReject = () => {
        NotificationService.error({ message: "Transaction rejected!" });
        setShowTransactionModal(false);
    };

    useEffect(() => {
        getUserInfo();
        _getTransactions();
    }, []);

    useEffect(() => {
        applyFilter();
    }, [filterKey, activeTab, transactions]);

    return (
        <AppLayout
            text={
                <h2 className="text-[24px] font-bold leading-[30px] text-[#1A1C1E]">
                    Transfer
                </h2>
            }
        >
            <div className="px-[2rem] py-[4rem] w-full">
                {/* Tabs */}
                <div className="flex space-x-4 mb-4">
                    <button
                        className={`px-6 py-2 border-b-2 ${activeTab === "local" ? "border-black font-semibold" : "border-gray-300"}`}
                        onClick={() => setActiveTab("local")}
                    >
                        Local
                    </button>
                    <button
                        className={`px-6 py-2 border-b-2 ${activeTab === "foreign" ? "border-black font-semibold" : "border-gray-300"}`}
                        onClick={() => setActiveTab("foreign")}
                    >
                        Foreign
                    </button>
                </div>

                {/* Filter Dropdown */}
                <div className="mb-[2rem] flex justify-between items-center">
                    <h2 className="text-[18px] font-semibold text-gs-text leading-[27px]">
                        {activeTab === "local" ? "Local Transactions" : "Foreign Transactions"}
                    </h2>
                    <select
                        value={filterKey}
                        onChange={(e) => setFilterKey(e.target.value)}
                        className="border border-gs-borderInput px-4 py-2 rounded-md"
                    >
                        {filterOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Transactions Table */}
                <CardTransactions data={filteredTransactions} onRowClick={handleRowClick} />

                {/* Transaction Details Modal */}
                {showTransactionModal && selectedTransaction && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                        <div className="bg-white p-6 rounded-md w-[90%] max-w-md">
                            <h3 className="text-lg font-bold mb-4">Transaction Details</h3>
                            <p><strong>Date:</strong> {new Date(selectedTransaction.createdAt)?.toLocaleString()}</p>
                            <p><strong>Type:</strong> {selectedTransaction.type}</p>
                            <p><strong>Amount:</strong> {selectedTransaction.amount}</p>
                            <p><strong>Status:</strong> {selectedTransaction.status}</p>
                            <p><strong>Reference:</strong> {selectedTransaction.reference}</p>
                            <div className="flex justify-end mt-4">
                                <button
                                    onClick={handleAccept}
                                    className="px-4 py-2 bg-green-500 text-white rounded-md mr-2"
                                >
                                    Accept
                                </button>
                                <button
                                    onClick={handleReject}
                                    className="px-4 py-2 bg-red-500 text-white rounded-md"
                                >
                                    Reject
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </AppLayout>
    );
}

export default Transfer;
